import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PrayerImg from '../../static/img/Prayer.png'
import PrayerHands from '../../static/img/Prayer Hands.png'

export const PrayerPageTemplate = ({ 
  title, 
  backgroundimage, 
  content, 
  contentComponent 
}) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >
        <div
          style={{
            display: 'flex',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <img src={PrayerImg} alt="Title" style={{ height: '300px' }} />
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">

              <h2 className="title is-size-3 has-text-weight-bold is-bold-light"
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginBottom: '20px',
                  justifyContent: 'center',
                  alignContent: 'center'}}>
                {title}
              </h2>
              <PageContent className="content" content={content} />
              <Link to="/contact" title="ContactLink">
                <img
                  className="link-button"
                  src={PrayerHands}
                  alt="Hands"
                  style={{
                    height: '100px',
                    display: 'flex',
                    margin: 'auto',
                    marginTop: '40px',
                    marginBottom: '10px',
                    padding: '10px',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                  }}
                />
              </Link>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

PrayerPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PrayerPage = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <PrayerPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

PrayerPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PrayerPage

export const PrayerPageQuery = graphql`
  query PrayerPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
